import * as React from "react"
import Main from "../../components/atoms/Main/Main";

import {StaticImage} from "gatsby-plugin-image";
import MainNav from "../../components/molecules/MainNav/MainNav";
import Block from "../../components/atoms/Block/Block";
import BlockArea from "../../components/atoms/BlockArea/BlockArea";

// markup
const IndexPage = () => {
  return (
    <Main
      poll
      footerContent={(
        <>
          <MainNav active="home"/>
        </>
      )}
    >
      <StaticImage src="../../images/app/home.png" alt="" layout="fullWidth"/>
      <BlockArea>
        <Block height="300%"/>
        <Block height="35%" width="50%" to="/app/spiele_paare"/>
        <Block height="35%" width="50%" to="/app/spiele_plakate"/>
        <Block height="35%" width="50%" href="https://www.superhelden-undercover.de/"/>
      </BlockArea>
    </Main>
  )
}

export default IndexPage
