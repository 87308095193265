import * as React from "react"
import styled from "styled-components";
import {Link} from "gatsby";

const LinkStyled = styled(Link)`
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
`;

const AStyled = styled.a`
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
`;

const BlockStyled = styled.div`
  width: ${props => props.width};
  padding-bottom: ${props => props.height};
  position: relative;
`;

const Block = ({to=null, href="", height, width="100%", children=null}) => (
  <BlockStyled height={height} width={width}>
    {to ? <LinkStyled to={to}>
      {children}
    </LinkStyled> : null}
    {href ? <AStyled href={href} target="_blank">
      {children}
    </AStyled> : null}
    {(!to && !href) ? children : null}
  </BlockStyled>
)

export default Block;