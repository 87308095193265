import * as React from "react"
import styled from "styled-components";


const BlockAreaStyled = styled.div`
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const BlockArea = ({ children }) => (
  <BlockAreaStyled>
    { children }
  </BlockAreaStyled>
)

export default BlockArea;